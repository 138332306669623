import React from 'react'

function Departments() {
    return (
        // <section className="clients style-4">
        //     <div className="container">
        //         <div className="text-center">
        //             <h5 className="fw-bold mb-60">Our <span className="color-blue4">Sister Concerns</span> </h5>
        //         </div>
        //         <div className="client-logos pb-70">
        //             <div className="row align-items-center">
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/1.png" alt />
        //                     </a>
        //                 </div>
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/2.png" alt />
        //                     </a>
        //                 </div>
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/3.png" alt />
        //                     </a>
        //                 </div>
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/4.png" alt />
        //                     </a>
        //                 </div>
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/5.png" alt />
        //                     </a>
        //                 </div>
        //                 <div className="col-6 col-lg-2">
        //                     <a href="#" className="img d-block">
        //                         <img src="assets/img/logos/6.png" alt />
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>


        <section className=" section-padding pt-50 style-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-head text-center mb-60 style-4">
                            <h2 className="mb-20"> Our  <span> Sister Concerns </span> </h2>
                            <p>
                                We provide perfect IT Solutions for your business
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="row">
                        <div className="col-lg-3">
                            <a href="#" className="features-card mb-30 style-5">
                                <div className="icon">
                                    <img src="assets/img/departments/brand plus.png" alt />
                                </div>
                                <div className="info">
                                    <h5 className="card-title">
                                       BrandPlus
                                    </h5>
                                    <p className="text">
                                    Advertising and branding have a major role in your business. It determines the strength of your venture different from your competitors and opens a wide market.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3">
                            <a href="http://conspix.com/" target="_blank" className="features-card mb-30 style-5">
                                <div className="icon">
                                    <img src="assets/img/departments/Conspix.png" alt />
                                </div>
                                <div className="info">
                                    <h5 className="card-title">
                                        ConsPix
                                    </h5>
                                    <p className="text">
                                    Being creative people we aim to design everything to catch the attention of the target group. Dedication is our benchmark in making unique and creative graphics, animations and videos in tune with the new trends in marketing. 
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://okutics.com/" target="_blank" className="features-card mb-30 style-5">
                                <div className="icon">
                                    <img src="assets/img/departments/okutics.png" alt />
                                </div>
                                <div className="info">
                                    <h5 className="card-title">
                                        Okutics
                                    </h5>
                                    <p className="text">
                                    Okutics is our separate  division for all digital solutions. We are focusing on quality and capacity building for clients in each and every service.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3">
                            <a href="#" className="features-card mb-30 style-5">
                                <div className="icon">
                                    <img src="assets/img/departments/Youphoria.png" alt />
                                </div>
                                <div className="info">
                                    <h5 className="card-title">
                                        Youphoria
                                    </h5>
                                    <p className="text">
                                    Sharpen your business to multiply your profit
Youphoria  is a system by Bislep to provide the training needed to nurture the business sector.
                                    </p>
                                </div>
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Departments