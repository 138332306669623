import React, {useState} from 'react'
import { ActiveMenuContextProvider } from '../contexts/ActiveMenu-Context'
import Footer from './partials/Footer'
import Header from './partials/Header'

function Layout({children}) {


  return (
    <div>
      <ActiveMenuContextProvider >
        <Header />
        {children}
        <Footer />
      </ActiveMenuContextProvider>
    </div>
  )
}


export default Layout