import React from 'react'
import { Link } from 'react-router-dom'
import { useActiveMenuContext } from '../contexts/ActiveMenu-Context'
import logo from '../assets/img/logo.png'
function NavBar() {
    const activeMenu = useActiveMenuContext();
    const active = activeMenu.active;
    const handleClick = activeMenu.handleClick;
    return (

        
        <nav className="navbar navbar-expand-lg navbar-light style-4">
            <div className="container">
                <Link className="navbar-brand" to="/" onClick={()=>{handleClick('/')}}>
                    <img src={logo} style={{width:"70%"}} alt />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
                        <li className="nav-item dropdown">
                          
                            <Link className={"nav-link "+(active === '/' ? 'active' : "")} to="/" onClick={()=>{
                                handleClick('/')

                            }}>
                                Home
                            </Link>
                            
                        </li>
                        <li className="nav-item dropdown">
                            <Link className={"nav-link "+(active === '/about' ? 'active' : "")} to="/about" onClick={()=>{
                                handleClick('/about')

                            }}>
                                About
                            </Link>
                            
                        </li>
                        <li className="nav-item">
                            <Link className={"nav-link "+(active === '/services' ? 'active' : "")} to="/services" onClick={()=>{
                                handleClick('/services')
                            }}>
                                Services
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={"nav-link "+(active === '/contact' ? 'active' : "")} to="/contact" onClick={()=>{
                                handleClick('/contact')
                            }}>
                                contact
                            </Link>
                        </li>
                    </ul>
                    {/* <div className="nav-side"> */}
                        {/* <div className="d-flex align-items-center">
                            <a href="#" className="search-icon me-4">
                                <i className="bi bi-person" />
                            </a>
                            <a href="page-contact-app.html" className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold">
                                <span>Join iteck Hub <i className="bi bi-arrow-right ms-1" /> </span>
                            </a>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
        </nav>
 
    )
}

export default NavBar