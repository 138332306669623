import React from 'react'
import { useEffect } from 'react'
import $ from 'jquery'
import Swiper from 'swiper'
function Service() {

    useEffect(()=>{
        var swiper = new Swiper('.services-slider.style-6 .swiper-container', {
            slidesPerView: 6,
            centeredSlides: true,
            spaceBetween: 0,
            speed: 1000,
            pagination: false,
            navigation: false,
            mousewheel: false,
            keyboard: true,
            autoplay: {
                delay: 1,
            },
            loop: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 1,
                },
                787: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 6,
                }
            },
            on: {
                slideChange: function () {
                  var activeIndex = this.activeIndex;
                  var realIndex = this.slides.eq(activeIndex).attr('data-swiper-slide-index');
                 $('.swiper-slide').removeClass('swiper-slide-nth-prev-2 swiper-slide-nth-next-2');
                 $('.swiper-slide[data-swiper-slide-index="'+realIndex+'"]').prev().prev().addClass('swiper-slide-nth-prev-2');
                 $('.swiper-slide[data-swiper-slide-index="'+realIndex+'"]').next().next().addClass('swiper-slide-nth-next-2');
                },
              }
        });
    })
    return (
        <section className="services bg-white pb-50 style-6">
            <div className="container">
                <div className="section-head text-center style-4 mb-60">
                    {/* <small className="title_small"> Software Apps </small> */}
                    <h2 className="mb-20"> Our Top <span> Services </span> </h2>
                    <p> Notero intergrate with popular apps. Help you easy to connect and collaboration </p>
                </div>
            </div>
            <div className="content">
                <div className="services-slider position-relative style-6">
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/4331/4331955.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Branding</h5>
                                        <div className="text">
                                        A brand is a product, service or concept that is publicly distinguished from other products, services or concepts so that it can be easily communicated and usually marketed.
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/2645/2645725.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>App Development</h5>
                                        <div className="text">
                                        Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones.
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="assets/img/icons/serv_icons/18.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Website Design and Development</h5>
                                        <div className="text">
                                        If you own a business, you need a website. This is your online shop window to sell your products or services to your target audience. Web development is the structure behind building and developing a fantastic and responsive website for your business.
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="assets/img/icons/serv_icons/15.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Digital Marketing</h5>
                                        <div className="text">
                                        Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services.
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/2779/2779775.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Graphic Design</h5>
                                        <div className="text">
                                        Graphic design can be used by companies to promote and sell products through advertising, by websites to convey complicated information in a digestible way through infographics, or by businesses to develop an identity through branding, among other things.
                                        </div>
                                    </div>
                                </a>
                            </div>
                           
                            
                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/5371/5371295.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Video Editing</h5>
                                        <div className="text">
                                        To structure and present all video information, including films and television shows, video advertisements and video essays. Video editing has been dramatically democratized in recent years by editing software available for personal computers
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="assets/img/icons/serv_icons/17.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Social Media Management</h5>
                                        <div className="text">
                                        Social media management is the process of analyzing social media audiences and developing a strategy that’s tailored to them, creating and distributing content for social media profiles, monitoring online conversations, and reporting on social media performance.
                                        </div>
                                    </div>
                                </a>
                            </div>

                            

                            <div className="swiper-slide">
                                <a href="#" className="service-card style-6">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/5361/5361391.png" alt />
                                    </div>
                                    <div className="info">
                                        <h5>Business Training</h5>
                                        <div className="text">
                                        Training programs help individuals, teams, and departments unite as a single organizational structure. Employees who know how their job role supports the overall mission and goals of their company can draw a line between “my work” and “my company's success.”
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Service