import React from 'react'
import Layout from '../layout/Layout'
import aboutImg from '../assets/img/about/ipad.png'
function AboutUs() {
    return (
        <Layout>
            <main className="about-page style-5">
                {/* ====== start about ====== */}
                <section className="about section-padding style-4">

                    <div className="content frs-content">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6">
                                    <div className="img mb-30 mb-lg-0">
                                        <img src={aboutImg} alt />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="info">
                                        <div className="section-head style-4">
                                            {/* <small className="title_small">Notero - Easy Notes App</small> */}
                                            <h2 className="mb-30">A CREATIVE POOL FOR <span> BUSINESS PEOPLE </span> </h2>
                                        </div>
                                        <p className="text mb-40" style={{ textAlign: "justify" }}>
                                            We are giving services with maximum utility in branding, leading different advertisement activities, designing - graphics & video, web development, e-commerce, marketing activities, sales promotion activities, digital marketing, affiliated marketing, business event management, business training, and planning of 360-degree marketing,commercial exterior, and interior designing, etc.
                                        </p>
                                        {/* 
                                <h4>Our Mission</h4>
                                <p>As the economy strengthens, everyone grows to their own potential</p>

                                <h4>Our Vision </h4>
                                <p>Provide  the most of the activities required for all businesses to move forward profitably</p>

                                <br /> */}
                                        <ul>
                                            <li className="d-flex align-items-center mb-3">
                                                <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                                    <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                                </small>
                                                <h6 className="fw-bold">Always keep formulas for success</h6>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                                    <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                                </small>
                                                <h6 className="fw-bold">You should always renovate your business</h6>
                                            </li>

                                            <li className="d-flex align-items-center mb-3">
                                                <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                                    <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                                </small>
                                                <h6 className="fw-bold">Keep talking attractively with your customer</h6>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                                    <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                                </small>
                                                <h6 className="fw-bold">Business should always be monitored and embrace change</h6>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                                    <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                                </small>
                                                <h6 className="fw-bold">There are always new opportunities in business</h6>
                                            </li>



                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="assets/img/about/about_s4_lines.png" alt className="lines" />
                        <img src="assets/img/about/about_s4_bubble.png" alt className="bubble" />
                    </div>
                </section>

                <section >
                    <div className="">

                        <div className="container">
                            <div className="row gx-0 justify-content-end">
                                <div className="col-md-12 ">
                                    <div className="row info">

                                        <div className='col-md-6'>
                                            <div className="section-head long-shape mb-40 style-4">
                                                <h2>Our <span>Mission</span></h2>
                                            </div>
                                            <h5 className="h5">
                                                “As the economy strengthens, everyone grows to their own potential.”
                                            </h5>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="section-head long-shape mb-40 style-4">
                                                <h2 >Our <span>Vision</span></h2>
                                            </div>

                                            <h5 className="h5">
                                                “Provide  the most of the activities required for all businesses to move forward profitably.”
                                            </h5>
                                        </div>
                                        {/* <div className="text mb-20">
                                            Iteck Co is the partner of choice for many of the world’s leading enterprises, SMEs
                                            and technology challengers. We help businesses elevate their value through custom
                                            software development, product design, QA and consultancy services.
                                        </div>
                                        <div className="text mb-70">
                                            Iteck specializes in technological and IT-related services such as product
                                            engineering, warranty management, building cloud, etc.
                                        </div> */}
                                        {/* <a href="page-about-5.html" className="btn rounded-pill bg-blue2 sm-butn text-white">
                                            <span>More About Us</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="info">
                            <div className="section-head pt-80 style-4">
                                <h2 className="mb-30 ">Our <span> History </span> </h2>
                            </div>
                        </div>

                        <div className='info'>
                            <p className="text mb-10" style={{ textAlign: "justify" }}>
                                A business sector needs a lot of support to grow. Bislape is based on the idea of building a system that is reliable for any type of business.
                            </p>
                            <p className="text mb-10" style={{ textAlign: "justify" }}>
                                Business is like painting work. The person starts with a blank canvas. Every artist must learn is that even the failed pieces are essential. Your business is the total of creative actions of new knowledge and ideas. BisLap is a novel pool to lead, help, guide, and provide all valuable services for boosting your business. In the present scenario, people are attracted by a very creative intervention in everyday business.
                            </p>

                            <p className="text mb-40" style={{ textAlign: "justify" }}>
                                We are giving services with maximum utility in branding, leading different advertisement activities, designing - graphics & video, web development, e-commerce, marketing activities, sales promotion activities, digital marketing, affiliated marketing, business event management, business training, and planning of 360-degree marketing, commercial exterior, and interior designing, etc. We help you to strengthen your business, realize your ideas into success, and ease your works, save you time and money, help to update your business knowledge, and sharpen your business skills. BisLap is a single roof of everything for business people.
                            </p>
                        </div>
                    </div>
                </section>

                {/* ====== end about ====== */}
                {/* ====== start team ====== */}
                {/* <section className="team section-padding style-6">
                    <div className="content">
                        <div className="container">
                            <div className="section-head text-center style-4 mb-60">
                                <small className="title_small"> The Team Work </small>
                                <h2 className="mb-20"> Our <span> Leaders </span> </h2>
                                <p> The Professional Creative Team for Growth-up Works </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="team-card mb-30 mb-lg-0 style-6">
                                        <div className="img img-cover">
                                            <img src="assets/img/team/1.jpeg" alt />
                                            <div className="social-icons">
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-github" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a className="d-block" href="#"><h6>Michael Edwards</h6></a>
                                            <small>CEO Founder</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="team-card mb-30 mb-lg-0 style-6">
                                        <div className="img img-cover">
                                            <img src="assets/img/team/5.jpeg" alt />
                                            <div className="social-icons">
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-github" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a className="d-block" href="#"><h6>Bobby Kane</h6></a>
                                            <small>CTO</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="team-card mb-30 mb-lg-0 style-6">
                                        <div className="img img-cover">
                                            <img src="assets/img/team/3.jpeg" alt />
                                            <div className="social-icons">
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-github" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a className="d-block" href="#"><h6>Robert Downey Jr</h6></a>
                                            <small>Project Manager</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="team-card style-6">
                                        <div className="img img-cover">
                                            <img src="assets/img/team/4.jpeg" alt />
                                            <div className="social-icons">
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-github" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a className="d-block" href="#"><h6>Andrew Robertson</h6></a>
                                            <small>Marketing Leader</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section> */}
                {/* ====== end team ====== */}

            </main>

        </Layout>
    )
}

export default AboutUs