import React, {useState} from "react";

export const ActiveMenuContext  = React.createContext({})

export function ActiveMenuContextProvider(props) {
    const [active, setActive] = useState(window.localStorage.getItem('activeUrl') ? window.localStorage.getItem('activeUrl') : "/");

    const  handleClick = (url) => {
        window.localStorage.setItem('activeUrl', url)
        setActive(url)
    }

    return (
        <ActiveMenuContext.Provider value={{active, handleClick}}>
            {props.children}
        </ActiveMenuContext.Provider>
    )

}


export const useActiveMenuContext = () => React.useContext(ActiveMenuContext);