import React from 'react'
import Layout from '../layout/Layout'
import mail from '../assets/img/icons/mail3d.png'
import location from '../assets/img/icons/map3d.png'
import chat from '../assets/img/icons/msg3d.png'
function ContactUs() {
    return (
        <Layout>
            <main className="contact-page style-5">
                {/* ====== start contact page ====== */}
                <section className="community section-padding style-5">
                    <div className="container">
                        <div className="section-head text-center style-4 mb-40">
                            <small className="title_small">Contact us</small>
                            <h2 className="mb-20">Get In  <span> Touch </span> </h2>
                            <p>We will contact again after receive your request in 24h</p>
                        </div>
                        <div className="content rounded-pill">
                            <div className="commun-card">
                                <div className="icon icon-45">
                                    <img src={mail} alt />
                                </div>
                                <div className="inf">
                                    <h5>info@bislap.com </h5>
                                </div>
                            </div>
                            <div className="commun-card">
                                <div className="icon icon-45">
                                    <img src={location} alt />
                                </div>
                                <div className="inf">
                                    <h6>4th Floor CD Tower, Mini bypass rd, Calicut</h6>
                                </div>
                            </div>
                            <div className="commun-card">
                                <div className="icon icon-45">
                                    <img src={chat} alt />
                                </div>
                                <div className="inf">
                                    <h5>(+91) 994 6666 510</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="contact section-padding pt-0 style-6">
                    <div className="container">
                        <div className="content">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <form action="contact.php" className="form" method="post">
                                        <p className="text-center text-danger fs-12px mb-30">The field is required mark as *</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <input type="text" name="name" className="form-control" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <input type="text" name="email" className="form-control" placeholder="Email Address *" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <input type="text" name="phone" className="form-control" placeholder="Phone Number (option)" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <input type="text" name="website" className="form-control" placeholder="Your Website (option)" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-20">
                                                    <select name="option" className="form-select">
                                                        <option value="how can we help" selected>How can we help you?</option>
                                                        <option value="option 1">option 1</option>
                                                        <option value="option 2">option 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <textarea rows={10} className="form-control" placeholder="How can we help you?" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 text-center">
                                                <div className="form-check d-inline-flex mt-30 mb-30">
                                                    <input className="form-check-input me-2 mt-0" type="checkbox" defaultValue id="flexCheckDefault" />
                                                    <label className="form-check-label small" htmlFor="flexCheckDefault">
                                                        By submitting, i’m agreed to the <a href="#" className="text-decoration-underline">Terms &amp; Conditons</a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 text-center">
                                                <input type="submit" defaultValue="Send Your Request" className="btn rounded-pill bg-blue4 fw-bold text-white text-light fs-12px" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <img src="assets/img/icons/contact_a.png" alt className="contact_a" />
                            <img src="assets/img/icons/contact_message.png" alt className="contact_message" />
                        </div>
                    </div>
                </section> */}
                {/* ====== end contact page ====== */}
                {/* ====== start contact page ====== */}
                <div className="map">

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.0231374792857!2d75.78510231459369!3d11.259708091994723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6594a0976309b%3A0x19862df7e377cb20!2sBISLAP!5e0!3m2!1sen!2sin!4v1665993280054!5m2!1sen!2sin" height={500} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24219.60999175365!2d-73.9764341314902!3d40.64198229194528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b400c94a227%3A0x18e2a4d3fb21f0ec!2sFlatbush%2C%20Brooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2seg!4v1651361759450!5m2!1sen!2seg" height={500} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
                </div>
                {/* ====== start contact page ====== */}
            </main>

        </Layout>
    )
}

export default ContactUs