import React from 'react'

function Testimonial() {
    return (
        <section className="testimonials style-4 pt-70">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-head style-4">
                                <small className="title_small">Testimonials</small>
                                <h2 className="mb-30">Loved From <span> Customers </span> </h2>
                            </div>
                            <p className="text mb-40">
                                Bislap loved from customer worldwide and get trusted from big companies.
                            </p>

                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCPftGPOt5wwfdWtZwQ7CjuV_G9nJFtgrz4nkqH66kEbz9WGTkQ5W3kezN-C9L2W4lmvA&usqp=CAU" alt="" />
                            {/* <div className="numbs">
                                <div className="num-card">
                                    <div className="icon img-contain">
                                        <img src="assets/img/icons/testi_s4_ic1.png" alt />
                                    </div>
                                    <h2>2,5M+</h2>
                                    <p>Downloaded and <br /> Installation</p>
                                </div>
                                <div className="num-card">
                                    <div className="icon img-contain">
                                        <img src="assets/img/icons/testi_s4_ic2.png" alt />
                                    </div>
                                    <h2>4.8/5</h2>
                                    <div className="stars">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                    <p>Based on 1,258 reviews</p>
                                </div>
                            </div> */}
                            <div className="d-flex align-items-center mt-70">
                                <a href="https://www.apple.com/app-store/" className="btn rounded-pill bg-blue4 fw-bold text-white me-4" target="_blank">
                                    <small> See Reviews On App Store </small>
                                </a>
                                <a href="https://youtu.be/pGbIOC83-So?t=21" data-lity className="play-btn">
                                    <span className="icon me-2">
                                        <i className="fas fa-play ms-1" />
                                    </span>
                                    <strong className="small">View <br /> Promotion</strong>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testi-cards">
                                <div className="client_card">
                                    <div className="user_img">
                                        <img src="assets/img/testimonials/user4.png" alt />
                                    </div>
                                    <div className="inf_content">
                                        <div className="stars mb-2">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h6>
                                            “You can even send emails to Evernote and gather  <br /> all of the things you need in a single place.”
                                        </h6>
                                        <p>jurgen k.   <span className="text-muted"> /  Senior Marketing at <span>Brator</span> </span></p>
                                    </div>
                                </div>
                                <div className="client_card">
                                    <div className="user_img">
                                        <img src="assets/img/testimonials/user5.png" alt />
                                    </div>
                                    <div className="inf_content">
                                        <div className="stars mb-2">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h6>
                                            “Notero - 1st my choice for notes app. Awesome”
                                        </h6>
                                        <p>foden p.  <span className="text-muted"> /  Director at <span>Ecoland Resort</span> </span></p>
                                    </div>
                                </div>
                                <div className="client_card">
                                    <div className="user_img">
                                        <img src="assets/img/testimonials/user6.png" alt />
                                    </div>
                                    <div className="inf_content">
                                        <div className="stars mb-2">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h6>
                                            “.This app is seriously good. It’s simple, clean and <br /> a real joy to use.”                                  </h6>
                                        <p>Kerry T. <span className="text-muted"> /  Designer at <span>Teckzone Inc</span> </span></p>
                                    </div>
                                </div>
                                <img src="assets/img/contact_globe.svg" alt className="testi-globe" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Testimonial