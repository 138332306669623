import React from 'react'
import { Link } from 'react-router-dom'
import About from '../components/About'
import Clients from '../components/Clients'
import Departments from '../components/Departments'
import Service from '../components/Service'
import Testimonial from '../components/Testimonial'
import { ActiveMenuContext, useActiveMenuContext } from '../contexts/ActiveMenu-Context'
import bg1 from '../assets/img/home-bg-1.png'
import header4Bubble from '../assets/img/header/header_4_bubble.png'
import Layout from '../layout/Layout'

function Home() {

  
   
    return (
        
                <Layout>
                     <ActiveMenuContext.Consumer>
                                                    {({active, handleClick}) => (
                                                        <div>
                    <header className="style-4">
                        <div className="content">
                            <div className="container">
                                <div className="row gx-0">
                                    <div className="col-lg-6">
                                        <div className="info">
                                            <small className="mb-90 title_small"></small>
                                            <h1 className="mb-30">We Shape The <span> Perfect Solutions </span>  </h1>
                                            <p className="text">A creative pool to improve your business .</p>
                                            <div className="d-flex align-items-center mt-50">
                                               
                                                <Link to="/services" onClick={()=>handleClick('/services')} className="btn rounded-pill bg-blue4 fw-bold text-white me-4">
                                                    <small>
                                                        {/* <i className="fab fa-apple me-2 pe-2 border-end" />  */}
                                                        Our Services
                                                    </small>
                                                </Link>
                                                
                                                {/* <a href="https://youtu.be/pGbIOC83-So?t=21" data-lity className="play-btn">
                                            <span className="icon me-2">
                                                <i className="fas fa-play ms-1" />
                                            </span>
                                            <strong className="small">View <br /> Promotion</strong>
                                        </a> */}
                                            </div>
                                            {/* <span className="mt-100 me-5">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-1">
                                            <i className="fas fa-sync" />
                                        </small>
                                        <small className="text-uppercase">Free 14 Days Trial</small>
                                    </span>
                                    <span className="mt-100">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-1">
                                            <i className="fas fa-credit-card" />
                                        </small>
                                        <small className="text-uppercase">One time payment</small>
                                    </span> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img">
                                            <img src={bg1} alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={header4Bubble} alt className="bubble" />
                        </div>
                        {/* <img src="assets/img/header/header_4_wave.png" alt className="wave" /> */}
                    </header>


                    <main className='services-page style-5 portfolio-page style-1'>
                        {/* <Departments/> */}

                        <About />

                        {/* <Departments/> */}

                        <Service />

                        <Clients />

                        {/* <Testimonial/> */}


                        <section className="download section-padding style-5 bg-light">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                        <div className="content text-center">
                                            <div className="section-head text-center style-4">
                                                <h2 className="mb-20"> Access your business potentials today &amp; find opportunity for  <span> bigger success </span> </h2>
                                            </div>
                                            <div className="butns mt-70">
                                                <Link to="/contact" onClick={()=>handleClick('/contact')} className="btn rounded-pill bg-blue4 fw-bold text-white me-4"  >
                                                    <small> Start A Project Now </small>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src="assets/img/contact_globe.svg" alt className="contact_globe" />
                        </section>
                    </main>
                    </div>
                    )}
                    </ActiveMenuContext.Consumer>
                </Layout>
                
          
    )
}

export default Home