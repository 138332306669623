import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../layout/Layout'

function Services() {
    return (
        <Layout>
            <main className="portfolio-page style-1">
                {/* ====== start portfolio-projects ====== */}
                <section className="portfolio-projects section-padding pt-50 style-1 bg-white">
                    <div className="container">
                        <div className="section-head text-center style-4 mb-40">
                            {/* <small className="title_small">portfolio</small> */}
                            <h2 className="mb-20">Our  <span> Services </span> </h2>
                            <p>We have an experienced team of production and inspection personnel to ensure quality.</p>
                        </div>
                      
                        <section className="portfolio style-1">
                            <div className="content">
                                <div className="row mix-container">
                                    <div className="col-lg-4 mix security consultation">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-psd/elegant-document-with-envelope-stationery-mockup_47987-3109.jpg?w=1060&t=st=1666264302~exp=1666264902~hmac=7b0c95925cd6af00cd40e85e4a6f8a850523e1562ab910b74da2353698a55ae9" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Branding </a>
                                                </h5>
                                                {/* <small className="d-block color-main text-uppercase">IT Consultation</small> */}
                                                <div className="text" style={{textAlign:"justify"}}>
                                                A brand is a product, service or concept that is publicly distinguished from other products, services or concepts so that it can be easily communicated and usually marketed.
                                                </div>
                                                {/* <div className="tags">
                                                    <a href="#">Consultation</a>
                                                    <a href="#">Management</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 mix website development">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-vector/music-player-app-interface-concept_23-2148535798.jpg?w=996&t=st=1666264904~exp=1666265504~hmac=6b21009691ccacba3c42da51df69a5acef0a97eba392c5926cd2ebb8f74b38a2" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > App Development </a>
                                                </h5>
                                                {/* <small className="d-block color-main text-uppercase">Game Developemnt</small> */}
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones.
                                                </div>
                                                {/* <div className="tags">
                                                    <a href="#">VR/AR</a>
                                                    <a href="#">iOS &amp; Android</a>
                                                    <a href="#">Unity 2D/3D</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mix cloud design">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-vector/web-development-isometric-concept-infographics-composition-with-platforms-text-captions-people-characters-icons-screens-illustration_1284-60242.jpg?w=900&t=st=1666265194~exp=1666265794~hmac=177f8e09284b8d3684a77cfafbaf096fd8fbf13aab6de5b5912edb86d666d4cb" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Web Development </a>
                                                </h5>
                                                {/* <small className="d-block color-main text-uppercase">SEO analysis</small> */}
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                If you own a business, you need a website. This is your online shop window to sell your products or services to your target audience. Web development is the structure behind building and developing a fantastic and responsive website for your business.
                                                </div>
                                                {/* <div className="tags">
                                                    <a href="#">SEO Analysis</a>
                                                    <a href="#">Content Strategy</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mix website development cloud">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-photo/creative-process-development-ideas-diagram_53876-125422.jpg?w=996&t=st=1666265360~exp=1666265960~hmac=a23f2fc38fdd35def0f2d6434c22d29a7da9d53e6a8c2d236ebed11438f82d38" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Graphic Design </a>
                                                </h5>
                                            
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                Graphic design can be used by companies to promote and sell products through advertising, by websites to convey complicated information in a digestible way through infographics, or by businesses to develop an identity through branding, among other things.
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mix design consultation">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-vector/videographer-concept-video-production-filming-editing-cameraman-motion-designer-making-visual-content-media-with-special-equipment-flat-vector-illustration_613284-2994.jpg?w=996&t=st=1666265448~exp=1666266048~hmac=3039626ce3b322ef6246b1bf448afec5d6ac1f2cca6ee269ac7647a2673ea5c2" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Video Editing </a>
                                                </h5>
                                               
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                To structure and present all video information, including films and television shows, video advertisements and video essays. Video editing has been dramatically democratized in recent years by editing software available for personal computers
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 mix cloud design">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-vector/social-dashboard-concept-illustration_114360-3252.jpg?w=996&t=st=1666265557~exp=1666266157~hmac=a0381fef33820cac46de560f0f91dcf1331b3ae112edfb7f44e68514c77ac999" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Social Media Management </a>
                                                </h5>
                                              
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                Social media management is the process of analyzing social media audiences and developing a strategy that’s tailored to them, creating and distributing content for social media profiles, monitoring online conversations, and reporting on social media performance.
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mix design security">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-vector/hand-drawn-illustration-people-with-smartphone-marketing_52683-66659.jpg?w=996&t=st=1666264745~exp=1666265345~hmac=8ee9e8898b0831e245b2c76c991e3a18469adc2613c75446dfd35134e2a028d8" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Digital marketing  </a>
                                                </h5>
                                                {/* <small className="d-block color-main text-uppercase">Website Development, UI/UX Design</small> */}
                                                <div className="text" style={{textAlign:"justify"}}>
                                                Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services.
                                                </div>
                                                {/* <div className="tags">
                                                    <a href="#">WordPress</a>
                                                    <a href="#">PHP</a>
                                                    <a href="#">HTML/CSS</a>
                                                    <a href="#">Figma</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mix security cloud">
                                        <div className="portfolio-card mb-50">
                                            <div className="img">
                                                <img src="https://img.freepik.com/free-photo/business-success-report-graph-concept_53876-121032.jpg?w=900&t=st=1666265769~exp=1666266369~hmac=8629e16f1f002a6d00469ae68f2900bfa3df7fefb0cf72c644ebbd4ad1eafb21" alt />
                                            </div>
                                            <div className="info">
                                                <h5>
                                                    <a > Business Training </a>
                                                </h5>
                                             
                                                <div className="text"  style={{textAlign:"justify"}}>
                                                Training programs help individuals, teams, and departments unite as a single organizational structure. Employees who know how their job role supports the overall mission and goals of their company can draw a line between “my work” and “my company's success.”
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* <div className="text-center">
                                <a href="#" className="btn rounded-pill bg-blue4 fw-bold text-white me-4" target="_blank">
                                    <small> Show More (24) </small>
                                </a>
                            </div> */}
                        </section>
                    </div>
                </section>
                <section className="download section-padding style-5 bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="content text-center">
                                    <div className="section-head text-center style-4">
                                        <h2 className="mb-20"> Access your business potentials today &amp; find opportunity for  <span> bigger success </span> </h2>
                                    </div>
                                    <div className="butns mt-70">
                                        <Link to="/contact" className="btn rounded-pill bg-blue4 fw-bold text-white me-4" >
                                            <small> Start A Project Now </small>
                                        </Link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/img/contact_globe.svg" alt className="contact_globe" />
                </section>
                {/* ====== end portfolio-projects ====== */}
            </main>


        </Layout>
    )
}

export default Services