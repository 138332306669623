import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useEffect } from 'react';
import axios from 'axios'
import { useState } from 'react';
import { ColorRing } from 'react-loader-spinner'
function Clients() {
    const [clientLogo, setClientLogo] = useState([]);
    useEffect(() => {
        axios.post('https://okutics.com/api/clients').then((res) => {
            setClientLogo(res.data);
        })
    }, [])
    return (
        <section className="clients style-5 pb-100">
            <div className="section-head text-center mb-40 style-4">
                <h2 className="mb-20"> Trusted By Thoudsands <span> Business </span> </h2>
                <p>More than 15,000 companies trust and choose Iteck</p>
            </div>
            <div className="content">
                {
                    clientLogo.length > 0 ?
                        <Swiper
                            spaceBetween={0}
                        
                            centeredSlides={true}
                            speed={6000}
                            autoplay={{
                                delay: 1,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay]}
                            className="mySwiper"
                            breakpoints={{
                                300: {
                                    width: 300,
                                    slidesPerView: 2,
                                  },
                                // when window width is >= 640px
                                640: {
                                  width: 640,
                                  slidesPerView: 4,
                                },
                                // when window width is >= 768px
                                768: {
                                  width: 768,
                                  slidesPerView: 3,
                                },
                              }}
                        >

                            {
                                clientLogo.map((logo, index) => {
                                    return (
                                        <SwiperSlide>
                                            <a href="#" className="img">
                                                <img src={"https://okutics.com" + (logo.c_image)} alt />
                                            </a>
                                        </SwiperSlide>
                                    )
                                })
                            }



                        </Swiper>

                        :
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                }



                <Swiper
                    spaceBetween={0}
                    slidesPerView={6}
                    centeredSlides={true}
                    speed={6000}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    dir={'rtl'}

                    modules={[Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        300: {
                            width: 300,
                            slidesPerView: 2,
                          },
                        // when window width is >= 640px
                        640: {
                          width: 640,
                          slidesPerView: 4,
                        },
                        // when window width is >= 768px
                        768: {
                          width: 768,
                          slidesPerView: 3,
                        },
                      }}
                >
                    {clientLogo.length > 0 &&
                        clientLogo.reverse().map((logo, index) => {
                            return (
                                <SwiperSlide>
                                    <a href="#" className="img">
                                        <img src={"https://okutics.com" + (logo.c_image)} alt />
                                    </a>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>


            </div>
        </section>

    )
}

export default Clients