import React from 'react'
import { Link } from 'react-router-dom'
import { useActiveMenuContext } from '../../contexts/ActiveMenu-Context';
import logo from '../../assets/img/logo.png'
function Footer() {
    const activeMenu = useActiveMenuContext();
    const handleClick = activeMenu.handleClick;
    return (
        <footer className="style-2 pt-80 pb-60 bg-white border-top brd-light ">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="foot_logo">
                            <img src={logo} alt />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="foot_links text-uppercase text-center small">
                         
                            <Link to="/" className="mx-4" onClick={()=>{handleClick('/')}}>
                                Home
                            </Link>
                            <Link to="/about" className="mx-4" onClick={()=>{handleClick('/about')}}>
                                About
                            </Link>
                            <Link to="/services" className="mx-4" onClick={()=>{handleClick('/services')}}>
                                Services
                            </Link>
                            <Link to="/contact" className="mx-4" onClick={()=>{handleClick('/contact')}}>
                                Contact
                            </Link>
                            
                        </div>
                    </div>
                    <div className="col-md-3 text-end">
                        <div className="socail-icons">
                            <a href="#" className="icon-40 sm-butn btn border text-black rounded-circle hover-lightBlue border-lightBlue m-1 p-0 d-inline-flex align-items-center justify-content-center">
                                <i className="fab fa-twitter" />
                            </a>
                            <a href="https://www.facebook.com/bislap" target="_blank" className="icon-40 sm-butn btn border text-black rounded-circle hover-lightBlue border-lightBlue m-1 p-0 d-inline-flex align-items-center justify-content-center">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a href="#" className="icon-40 sm-butn btn border text-black rounded-circle hover-lightBlue border-lightBlue m-1 p-0 d-inline-flex align-items-center justify-content-center">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            <a href="#" className="icon-40 sm-butn btn border text-black rounded-circle hover-lightBlue border-lightBlue m-1 p-0 d-inline-flex align-items-center justify-content-center">
                                <i className="fab fa-instagram" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-30">
                    <div className="small">
                        <span className="op-6">Developed By </span>
                        &nbsp;
                        <a href="https://okutics.com/" target="_blank"> Okutics</a>
                   
                    </div>
                </div>
            </div>
        </footer>


    )
}

export default Footer