import React, {useState} from 'react'
import NavBar from '../../components/NavBar'
import TopBar from '../../components/TopBar'
import '../../assets/css/lib/bootstrap.min.css'
import '../../assets/css/lib/all.min.css'
import '../../assets/css/lib/animate.css'
import '../../assets/css/lib/jquery.fancybox.css'
import '../../assets/css/lib/lity.css'
import '../../assets/css/lib/swiper.min.css'
import '../../assets/css/style.css'
function Header() {
 
  return (
      <div>
        <TopBar />
        <NavBar />
      </div>
  )
}

export default Header