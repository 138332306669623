import React from 'react'
import { Link } from 'react-router-dom'
import { useActiveMenuContext } from '../contexts/ActiveMenu-Context'
import aboutImg from '../assets/img/about/ipad.png'
import s4Line from '../assets/img/about/about_s4_lines.png'
import s4Bubble from '../assets/img/about/about_s4_bubble.png'
import brandPlus from '../assets/img/departments/brand plus.png'
import conspix from '../assets/img/departments/Conspix.png'
import okutics from '../assets/img/departments/okutics.png'
import youphoria from '../assets/img/departments/Youphoria.png'
import backImg from '../assets/img/about/intg_back.png'
function About() {

    const activeMenu = useActiveMenuContext();
    const handleClick = activeMenu.handleClick;

    return (
        <section className="about style-4">
            <div className="content frs-content">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <div className="img mb-30 mb-lg-0">
                                <img src={aboutImg} alt />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="info">
                                <div className="section-head style-4">
                                    {/* <small className="title_small">Notero - Easy Notes App</small> */}
                                    <h2 className="mb-30">A CREATIVE POOL FOR <span> BUSINESS PEOPLE </span> </h2>
                                </div>
                                <p className="text mb-40" style={{ textAlign: "justify" }}>
                                    We are giving services with maximum utility in branding, leading different advertisement activities, designing - graphics & video, web development, e-commerce, marketing activities, sales promotion activities, digital marketing, affiliated marketing, business event management, business training, and planning of 360-degree marketing,commercial exterior, and interior designing, etc.
                                </p>
                                <ul>
                                    <li className="d-flex align-items-center mb-3">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                            <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                        </small>
                                        <h6 className="fw-bold">Always keep formulas for success</h6>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                            <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                        </small>
                                        <h6 className="fw-bold">You should always renovate your business</h6>
                                    </li>

                                    <li className="d-flex align-items-center mb-3">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                            <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                        </small>
                                        <h6 className="fw-bold">Keep talking attractively with your customer</h6>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                            <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                        </small>
                                        <h6 className="fw-bold">Business should always be monitored and embrace change</h6>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
                                            <img src={process.env.PUBLIC_URL + "Favcon.png"} alt="" />
                                        </small>
                                        <h6 className="fw-bold">There are always new opportunities in business</h6>
                                    </li>



                                </ul>
                                <Link to="/about" onClick={()=>handleClick('/about')} className="btn rounded-pill bg-blue4 fw-bold text-white mt-50">
                                    <small> Learn More </small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={s4Line} alt className="lines" />
                <img src={s4Bubble} alt className="bubble" />
            </div>



            <div>
                <div className="integration pt-60">
                    <div className="section-head text-center style-4">
                        <small className="title_small">Let's Introduce</small>
                        <h2 className="mb-20">Our <span>Sister Concerns </span> </h2>
                        <p>Notero intergrate with popular apps. Help you easy to connect and collaboration</p>
                    </div>
                    <div className="container">
                        <div className="content">
                            <div className="img">
                                <img src={brandPlus} alt className="mt-30" style={{ border: "4px solid #ea0979", borderRadius: "50%" }} />
                            </div>
                            <div className="img">
                                <a href='http://conspix.com/' target="_blank"> 
                                    <img src={conspix} alt className="mt-60" style={{ border: "4px solid #eb1947", borderRadius: "50%" }} />
                                </a>
                            </div>
                            <div className="img">
                                <a href='https://okutics.com/' target="_blank"> 
                                    <img src={okutics} alt className="mt-20" style={{ border: "4px solid #cd2127", borderRadius: "50%" }} />
                                </a>
                            </div>
                            <div className="img">
                                <img src={youphoria} alt className="mt-80" style={{ border: "4px solid #ab5b42", borderRadius: "50%" }} />
                            </div>

                        </div>
                    </div>
                    <img src={backImg} alt className="intg-back" />
                </div>
                {/* <img src="assets/img/about/about_s4_wave.png" alt className="top-wave" />
                <img src="assets/img/about/about_s4_wave.png" alt className="bottom-wave" /> */}
            </div>

        </section>

    )
}

export default About