import React from 'react'

function TopBar() {
    return (
        <div className="top-navbar style-4">
            <div className="container">
                <div className="content text-white">
                    {/* <span className="btn sm-butn bg-white py-0 px-2 me-2 fs-10px">
                        <small className="fs-10px">Special</small>
                    </span>
                    <img src="assets/img/icons/nav_icon/imoj_heart.png" alt className="icon-15" /> */}
                    {/* <span className="fs-10px op-6">Get </span> */}
                    <small className="op-10 fs-10px">
                        <i className='fa fa-phone' style={{transform: "rotate(90deg)"}}></i>
                    </small>
                    {/* <span className="fs-10px op-6">Get for New Account</span> */}
                    <a href="tel:+919946666510" className="fs-10px ms-2">+91 994 6666 510</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <small className="op-10 fs-10px">
                        <i className='fa fa-envelope' ></i>
                    </small>
                    {/* <span className="fs-10px op-6">Get for New Account</span> */}
                    <a href="mailto:info@bislap.com" className="fs-10px ms-2">info@bislap.com</a>
                </div>
            </div>
        </div>
    )
}

export default TopBar